import React, {useContext, useEffect} from "react";
import {StringParam, useQueryParam} from "use-query-params";
import {Button} from "react-bootstrap";
import {InGameFooter, LoadingSpinner, RedirectIfGameIsHosted} from "../../components/components";
import {finishedInterstitial} from "../../services/firebase";
import useIsLoading from "../../hooks/useIsLoading";
import appContent from "../../markdown/app-content";
import {navigate} from "gatsby";
import {AppAlertsContext} from "../../context/app-alerts-context";
import useNoTeamRedirect from "../../hooks/useNoTeamRedirect";
import GameContext from "../../context/game-context";
import useHasAddedPhoneNumberAndEmailGuard from "../../hooks/useHasAddedPhoneNumberAndEmailGuard";
import ApplyCustomGameTheme from "../../app/game/shared/apply-custom-game-theme/ApplyCustomGameTheme";
import MarkdownPageLayout from "../../components/layouts/markdown-page-layout/MarkdownPageLayout";
import useCurrentClueLogic from "../../hooks/useCurrentClueLogic";

const WaitingArea = () => {
  const [gameId] = useQueryParam("gameId", StringParam);
  const params = new URLSearchParams({gameId: gameId});
  const {game, team, isLoading: isLoadingGame} = useContext(GameContext);
  const {isLoading, setIsLoading} = useIsLoading(false);
  const gameLink = "/game/in-game";
  const {popConfirm, popError} = useContext(AppAlertsContext);
  const {finishedAllClues} = useCurrentClueLogic(game, team);
  useNoTeamRedirect(`/game/join-game?${params.toString()}`);
  useHasAddedPhoneNumberAndEmailGuard(`/game/phone-email-required?${params.toString()}`);

  useEffect(() => {
    if (team?.getShouldBePastWaitingRoom(game)) {
      void navigate(gameLink);
    }
  }, [game, team, gameLink]);

  const handleFinishInterstitial = async () => {
    setIsLoading(true);
    try {
      await finishedInterstitial(team);
    } catch (error) {
      popError(error.message);
    }
    setIsLoading(false);
  };

  return <LoadingSpinner isLoading={isLoading}>
    <RedirectIfGameIsHosted url={gameLink} game={game}>
      {!isLoadingGame && (
        <MarkdownPageLayout
          logo="sm"
          markdown={game && game.interstitialContent}
          footer={<InGameFooter gameId={gameId} finishedGame={finishedAllClues}/>}
        >
          <Button block onClick={() => popConfirm(
            appContent.modals.unhostedBeginGame,
            handleFinishInterstitial,
          )}>
            {appContent.inGame.interstitial.buttonText}
          </Button>
        </MarkdownPageLayout>
      )}
    </RedirectIfGameIsHosted>
    <ApplyCustomGameTheme/>
  </LoadingSpinner>;
};

export default WaitingArea;
