import { useEffect, useContext } from "react";
import { navigate } from "gatsby";
import GameContext from "../context/game-context";

export default function useNoTeamRedirect(redirectUrl) {
  const { team, isLoading } = useContext(GameContext);

  useEffect(() => {
    if (!isLoading && !team) {
      navigate(redirectUrl);
    }
  });
}
