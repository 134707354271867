import { useEffect, useContext } from "react";
import { navigate } from "gatsby";
import UserProfileContext from "../context/user-profile-context";
import GameContext from "../context/game-context";
import { GameOptions } from "../entities/game";

export default function useHasAddedPhoneNumberAndEmailGuard(redirectUrl) {
  const { userProfile } = useContext(UserProfileContext);
  const { game, team } = useContext(GameContext);

  useEffect(() => {
    if (!game || !team || !userProfile) {
      return;
    }

    if (game.getOption(GameOptions.COLLECT_PHONES)) {
      if (!userProfile.phoneNumber) {
        return navigate(redirectUrl);

      }
    }

    if (game.getOption(GameOptions.COLLECT_EMAILS)) {
      if (!userProfile.email) {
        return navigate(redirectUrl);
      }
    }

    if (game.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME)) {
      if (!userProfile.additionalFields[
        game.getOption(GameOptions.REQUIRED_ADDITIONAL_FIELD_NAME)
      ]) {
        return navigate(redirectUrl);
      }
    }
  });
}
